<template>
  <div :class="$vuetify.breakpoint.mdAndDown ? 'pt-0 pb-4' : 'py-4'">
    <v-row no-gutters justify="space-between" class="px-8"
           v-if="selectionNavigator == 'all' || $vuetify.breakpoint.lgAndUp">
      <v-col cols="6" :lg="2" style="align-items: center;">
        <v-row class="row_slots" no-gutters>
          <v-col cols="auto">
            <nav-slot class="icon_gameCard" v-if="this.gameType == 'rng'"></nav-slot>
            <nav-casino class="icon_gameCard" v-else></nav-casino>
          </v-col>
          <v-col cols="auto">
            <span class="home_slot_text px-3 text-capitalize">{{ $t(`label.${this.gameType}_short`) }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="text-right d-flex align-center">
        <v-btn outlined rounded color="#9C9FF3" class="text-lowercase" small
               :to="{ name: this.gameType == 'rng' ? routeName.SLOT : routeName.CASINO }">
          {{ $t("label.playNow") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="selectionNavigator == 'all'|| $vuetify.breakpoint.lgAndUp">
      <v-col cols="12" class="pa-0 px-8 mb-4">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" class="game-card-container mx-0">
      <v-col cols="12" md="12" class="px-0">
        <template v-for="gameType in storageGameProviderType">
          <v-row no-gutters :class="selectedGameCard == gameType.type ? 'px-8' : 'card-hidden'"
                 :key="gameType.type">
            <v-col cols="12" class="pt-2 game-card-box-container">
              <div class="game-card-box">
                <div class="game-card-box-item" v-for="provider in gameType.providers"
                     :key="provider.providerCode">
                  <div
                    @click="!isGameMaintenance(gameType.type, provider.providerCode) ? accessGame(gameType.type, provider.providerCode) : {}">
                    <div class="vendor-info">
                      <div v-if="!isGameMaintenance(gameType.type, provider.providerCode)"
                           class="game-card-play-button text-uppercase">{{
                          $t(`label.playNow`)
                        }}
                      </div>
                      <div v-else class="game-card-play-button text-uppercase">{{
                          $t(`label.underMaintenance`)
                        }}
                      </div>
                    </div>
                    <!--                                            <div class="game-card-maintenance-overlay"-->
                    <!--                                                 v-if="isGameMaintenance(gameType.type, provider.providerCode)"></div>-->
                    <v-img :aspect-ratio="311/234" class="game-card-image-avatar"
                           :src="`${mediaUrl}/vendor-card/${gameType.type}/${provider.providerCode}_avatar.png`"
                           alt />
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { uiHelper } from "@/util";
import { CACHE_KEY } from "@/constants/constants";
import { ROUTE_NAME } from "@/constants/route.constants";
import { DevicePlatform } from "@/constants/enums";
import { SHARED } from "@/constants/constants";
import NavSlot from "@/assets/svg/rng_icon.svg";
import NavCasino from "@/assets/svg/ld_icon.svg";

export default {
  name: "appGameCard",
  props: {
    selectionNavigator: {
      type: String,
      required: true,
      default: "all"
    },
    openLoginDialog: {
      type: Function,
      required: true,
      default: () => {
      }
    },
    gameType: {
      type: String,
      required: true,
      default: () => ""
    }
  },
  components: {
    NavSlot,
    NavCasino
  },
  data: () => ({
    routeName: ROUTE_NAME,
    mediaUrl: "/static/image/",
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`),
    selectedGameCard: ""
  }),
  computed: {
    ProviderTypes() {
      return this.$store.state.game.ProviderTypes;
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn;
    }
  },
  watch: {
    ProviderTypes() {
      this.storageGameProviderType = this.$store.state.game.ProviderTypes;
      this.initSelectedCard();
    }
  },
  created() {
    this.initSelectedCard();
  },
  methods: {
    isGameMaintenance(gameType, providerCode) {
      if (
        this.storageGameProviderType == null ||
        this.storageGameProviderType.find(x => x.type == gameType) == undefined ||
        this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode) == undefined
      ) {
        return false;
      } else {
        return this.storageGameProviderType.find(x => x.type == gameType).providers.find(x => x.providerCode == providerCode).isMaintenance;
      }
    },
    initSelectedCard() {
      if (this.storageGameProviderType != null && typeof this.storageGameProviderType[2] != undefined) this.selectedGameCard = this.gameType;
    },
    accessGame(type, providerCode) {
      let gaType = "";
      switch (type) {
        case "sb":
          gaType = "Sportsbook";
          break;
        case "fishing":
          gaType = "Fishing";
          break;
        case "ld":
          gaType = "Casino";
          break;
        case "special":
          gaType = "Special";
          break;
      }

      if (!this.isLoggedIn && uiHelper.getPlatform() == DevicePlatform.MOBILE && type != "rng" && type != "special") {
        this.openLoginDialog();
      } else {
        if (type == "sb") {
          if (!this.isLoggedIn) {
            this.openLoginDialog();
          } else {
            try {
              this.$ga.event("Game", "Real Play", `${providerCode.toUpperCase()} - ${gaType}`);
            } catch (err) {
              console.log(err);
            }
            this.$router.push({
              name: ROUTE_NAME.SPORTSBOOK_BY_PROVIDER,
              params: {
                provider: providerCode
              }
            });
          }
        } else if (type == "rng") {
          this.$router.push({
            name: ROUTE_NAME.SLOT_BY_PROVIDER,
            params: {
              provider: providerCode
            }
          });
        } else if (type == "crash") {
          this.$router.push({
            name: ROUTE_NAME.CRASH_BY_PROVIDER,
            params: {
              provider: providerCode
            }
          });
        } else if (type == "special") {
          this.$router.push({
            name: ROUTE_NAME.SPECIAL_BY_PROVIDER,
            params: {
              provider: providerCode
            }
          });
        } else {
          if (this.isLoggedIn) {
            let routeData = this.$router.resolve({
              name: ROUTE_NAME.LAUNCH_GAME_LOBBY,
              params: {
                provider: providerCode,
                type: type
              },
              query: {
                demo: 0
              }
            });
            try {
              this.$ga.event("Game", "Real Play", `${providerCode.toUpperCase()} - ${gaType}`);
            } catch (err) {
              console.log(err);
            }
            uiHelper.openPopup(routeData.href, "Game Play Lobby", 1200, 900);
          } else {
            this.openLoginDialog();
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
// .icon_gameCard {
//   fill: white;
//   color: black;
//   stroke: #9c9ff3;
//   width: 30px;
//   height: 33px;
//   padding-top: 4px;
// }

// .home_slot_text {
//   color: #9C9FF3;
//   float: left;
//   font-size: 26px !important;
// }

// .row_slots {
//   position: relative;
// }

// .row_slots img {
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   margin: auto;
// }

// .card-hidden {
//   display: none;
// }

// .game-card-container {
//   max-height: 450px;
// }

// .game-card-box-container {
//   overflow-x: scroll;
//   overflow-y: hidden;
//   white-space: nowrap;
//   max-width: 100vw;
// }

// .game-card-box {
//   .game-card-box-item {
//     display: inline-block;
//     width: 435px;
//     padding-right: 20px;
//   }

//   .game-card-box-item:hover {
//     .game-card-image-avatar {
//       opacity: 0.4;
//     }

//     .vendor-info {
//       opacity: 1;
//       height: auto;
//       width: auto;
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//     }

//     .vendor-title {
//       background: -webkit-linear-gradient(#FFA15C, #EDD763);
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;
//       text-shadow: 3px 3px 3px #FFFFFF;
//     }
//   }
// }

// .game-card-select {
//   .v-list-item {
//     margin-bottom: 13px;
//   }

//   .v-card {
//     border-radius: 10px !important;
//     background-image: linear-gradient(to right, var(--v-base_3-base), var(--v-base_3-base));
//     padding: 9px 0px;

//     .game-card-title {
//       font-size: 18px;
//     }

//     &.active {
//       background-image: linear-gradient(to right, var(--v-primary-base), var(--v-primary_2-base));
//     }
//   }
// }

// .game-card-box {
//   display: table;
//   table-layout: fixed;
//   width: 100%;
//   border-collapse: separate;
//   border-spacing: 0;
//   max-height: 412px;
//   cursor: pointer;

//   .game-card-box-item {
//     display: table-cell;

//     > div {
//       height: 309px;
//       position: relative;
//       margin: auto !important;

//       .vendor-info {
//         opacity: 0;
//         height: auto;
//         position: absolute;
//         bottom: 0;
//         margin: 0;
//         z-index: 2;
//         padding: 25px;
//         width: 100%;

//         .vendor-title {
//           font-size: 75px;
//           line-height: 75px;
//           margin: 0;
//           font-weight: bold;
//           color: var(--v-primary_2-base);
//           text-shadow: 3px 3px 5px #FFA15C;

//           &.ld {
//             font-size: 60px;
//             line-height: 60px;
//           }

//           &.rng {
//             font-size: 50px;
//             line-height: 50px;
//           }
//         }

//         .vendor-type {
//           font-size: 40px;
//           line-height: 40px;
//           color: white;
//           margin: 0;
//           font-weight: bold;
//           text-shadow: 3px 3px 5px black;

//           &.ld {
//             font-size: 30px;
//             line-height: 30px;
//           }

//           &.rng {
//             font-size: 25px;
//             line-height: 25px;
//           }
//         }

//         .game-card-play-button {
//           display: inline-block;
//           position: relative;
//           padding: 8px 0px 8px 0px;
//           text-align: center;
//           color: #fff;
//           margin-top: 15px;
//           font-weight: bold;
//           font-size: 22px;
//           height: auto;
//           width: 220px;
//           cursor: pointer;

//           &::before {
//             border-radius: 25px;
//             content: '';
//             box-shadow: 8px 3px 10px 0px rgba(0, 0, 0, 0.5);
//             width: 100%;
//             position: absolute;
//             height: 100%;
//             top: 0;
//             left: 0;
//             font-weight: bold;
//             background: linear-gradient(
//                     60deg,
//                     hsl(224, 85%, 66%),
//                     hsl(269, 85%, 66%),
//                     hsl(314, 85%, 66%),
//                     hsl(359, 85%, 66%),
//                     hsl(44, 85%, 66%),
//                     hsl(89, 85%, 66%),
//                     hsl(134, 85%, 66%),
//                     hsl(179, 85%, 66%)
//             );
//             background-size: 300% 300%;
//             background-position: 0 50%;
//             animation: moveGradient 4s alternate infinite;
//             z-index: -1;
//           }
//         }

//         @keyframes moveGradient {
//           50% {
//             background-position: 100% 50%;
//           }
//         }

//       }

//       .game-card-image {
//         height: 100%;
//         border-radius: 10px;
//         background-position: center right;
//         background-size: cover;
//       }

//       .game-card-image-avatar {
//         z-index: 1;
//         position: absolute;
//         bottom: 0;
//         right: 0;
//         border-radius: 20px;
//         width: 100%;
//       }

//       .game-card-maintenance-overlay {
//         background-color: rgba(0, 0, 0, 0.5);
//         width: 100%;
//         height: 100%;
//         opacity: 1;
//         position: absolute;
//         z-index: 1;
//         border-radius: 10px;
//       }
//     }
//   }
// }

// @media (max-width: 1650px) {
//   .game-card-box {
//     .game-card-box-item {
//       > div {
//         .vendor-info {
//           .vendor-title {
//             font-size: 70px;
//             line-height: 70px;
//           }

//           .vendor-type {
//             font-size: 35px;
//             line-height: 35px;
//           }

//           .vendor-type.ld {
//             font-size: 25px;
//             line-height: 25px;
//           }

//           .game-card-play-button {
//             font-size: 16px;
//             margin-left: 15px;
//           }
//         }
//       }
//     }
//   }
//   div {
//     .vendor-info {
//       .vendor-title.ld {
//         font-size: 50px;
//         line-height: 50px;
//       }
//     }
//   }

//   .game-card-select {
//     .v-list-item {
//       margin-bottom: 8px;
//     }

//     .v-card {
//       padding: 4px 0px;

//       .game-card-title {
//         font-size: 16px;
//       }
//     }
//   }
// }

// @media (max-width: 1500px) {
//   .game-card-box {
//     .game-card-box-item {
//       div {
//         .vendor-info {
//           .vendor-title {
//             font-size: 55px;
//             line-height: 55px;
//           }

//           .vendor-title.ld {
//             font-size: 45px;
//             line-height: 45px;
//           }

//           .vendor-type {
//             font-size: 30px;
//             line-height: 30px;
//           }
//         }
//       }
//     }
//   }
//   .game-card-select {
//     .v-list-item {
//       margin-bottom: 11px;
//     }

//     .v-card {
//       .game-card-title {
//         font-size: 16px;
//         padding: 10px 15px;
//       }
//     }
//   }
// }

// @media (max-width: 1350px) {
// }

// @media (max-width: 1263px) {
//   .game-card-select {
//     .v-list-item {
//       margin-bottom: 9px;
//     }

//     .v-card {
//       .game-card-title {
//         font-size: 14px;
//         padding: 11px 10px;
//       }
//     }
//   }
//   .game-card-box {
//     .game-card-box-item {
//       > div {
//         .vendor-info {
//           padding: 20px 10px;

//           .vendor-title {
//             font-size: 45px;
//             line-height: 45px;
//           }

//           .vendor-title.ld {
//             font-size: 40px;
//             line-height: 40px;
//           }

//           .vendor-type.ld {
//             font-size: 20px;
//             line-height: 20px;
//           }

//           .game-card-play-button {
//             font-size: 14px;
//             margin-left: 10px;
//             width: 140px;
//           }
//         }
//       }
//     }
//   }
// }

// @media (max-width: 1024px) {
//   .game-card-container-select-box {
//     min-width: 180px;
//   }
//   .game-card-select {
//     .v-list-item {
//       margin-bottom: 2px;
//     }

//     .v-card {
//       .game-card-title {
//         font-size: 13px;
//         padding: 4px 10px;
//       }

//       padding: 6px 0px;
//     }
//   }
//   .game-card-box {
//     .game-card-box-item {
//       div {
//         /*height: 246px;*/

//         .vendor-info {
//           .vendor-title.rng {
//             font-size: 30px;
//             line-height: 30px;
//           }

//           .vendor-title.ld {
//             font-size: 30px;
//             line-height: 30px;
//           }

//           .vendor-type {
//             font-size: 20px;
//             line-height: 20px;
//           }

//           .vendor-type.rng {
//             font-size: 20px;
//             line-height: 20px;
//           }
//         }
//       }
//     }
//   }
// }

// @media (max-width: 959px) {
//   .game-card-container-select-box {
//     background-color: var(--v-base_3-base) !important;
//   }
//   .game-card-select {
//     background-color: transparent !important;
//     display: table !important;
//     margin: auto !important;
//     padding: 5px 0px;

//     .v-list-item {
//       display: inline-block;
//       margin-bottom: 0;
//       min-height: auto;

//       .v-card {
//         background-color: transparent !important;
//         background-image: none;
//         padding: 0px 5px;

//         .game-card-title {
//           font-size: 18px;
//           font-weight: normal !important;
//           color: var(--v-base-base) !important;
//           border-radius: 0px !important;
//         }

//         &.active {
//           .game-card-title {
//             color: var(--v-secondary-base) !important;
//             border-bottom: 2px solid;
//             font-weight: bold !important;
//           }
//         }
//       }
//     }
//   }
//   .game-card-box {
//     .game-card-box-item {
//       div {
//         .vendor-info {
//           .vendor-title.rng {
//             font-size: 25px;
//             line-height: 25px;
//           }

//           .vendor-title.ld {
//             font-size: 25px;
//             line-height: 25px;
//           }

//           .vendor-type.ld {
//             font-size: 15px;
//             line-height: 15px;
//           }

//           .vendor-type.rng {
//             font-size: 15px;
//             line-height: 15px;
//           }

//           .game-card-play-button {
//             font-size: 12px;
//             margin-left: 10px;
//             margin-top: 0;
//             width: 125px;
//             padding: 8px 15px 8px 3px;
//           }
//         }
//       }
//     }
//   }
// }

// @media (max-width: 650px) {
//   .game-card-select .v-list-item .v-card .game-card-title {
//     font-size: 15px;
//   }
//   .game-card-box .game-card-box-item div .vendor-info .game-card-play-button {
//     font-size: 10px;
//     margin-left: 5px;
//     width: 110px;
//     padding: 8px 8px 8px 0px;
//   }
// }

// @media (max-width: 599px) {
//   .home_slot_text {
//     padding-top: 8px;
//     font-size: 18px !important;
//   }
//   /*.game-card-box .game-card-box-item > div .game-card-image-avatar {*/
//   /*    height: 85%;*/
//   /*}*/

//   .game-card-container {
//     width: 100%;
//     margin: 0;
//   }
//   .game-card-container-select-box {
//     padding: 10px 0px;
//   }
//   .game-card-box-container {
//     overflow-x: scroll;
//     overflow-y: hidden;
//     padding: 0;
//   }
//   .game-card-box {
//     .game-card-box-item {
//       display: table-cell;
//       width: 250px;

//       div {
//         height: 170px;

//         .vendor-info {
//           .vendor-type {
//             font-size: 15px;
//             line-height: 15px;
//           }

//           .vendor-type.ld {
//             font-size: 12px;
//             line-height: 12px;
//           }

//           .vendor-type.rng {
//             font-size: 12px;
//             line-height: 12px;
//           }
//         }
//       }
//     }
//   }
//   .game-card-select {
//     .v-card {
//       .game-card-title {
//         font-size: 14px !important;
//         padding: 5px 3px;
//       }
//     }
//   }
// }

// @media (max-width: 425px) {
//   .home_slot_text {
//     padding-top: 4px;
//   }
//   /*.game-card-box .game-card-box-item > div .game-card-image-avatar {*/
//   /*    height: 80%;*/
//   /*}*/
//   /*  .game-card-select {
//     .v-card {
//       .game-card-title {
//         font-size: 16px !important;
//         padding: 3px;
//         line-height: 1;
//       }
//     }
//   }*/
// }

// @media (max-width: 375px) {
//   /*.game-card-box .game-card-box-item > div .game-card-image-avatar {*/
//   /*    height: 75%;*/
//   /*}*/

//   .game-card-select .v-list-item .v-card {
//     padding: 0 1px;
//   }
//   .game-card-select {
//     .v-card {
//       .game-card-title {
//         font-size: 12px !important;
//       }
//     }
//   }
// }
</style>
